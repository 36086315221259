/* Hide scrollbar for Chrome, Safari and Opera */
body {
    padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom)
        env(safe-area-inset-left);
}

*::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
* {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    -webkit-tap-highlight-color: transparent;
}

:root {
    --rdp-cell-size: 50px !important;
    --rdp-accent-color: var(--chakra-colors-gray-100) !important;
}

.rdp-caption_label,
.rdp-head_cell {
    font-weight: 500 !important;
}

.rdp-day_selected {
    color: black !important;
    background-color: var(--chakra-colors-gray-100) !important;
}

.rdp-button {
    overflow: visible !important;
}

select:focus,
input:focus,
button:focus,
a:focus {
    outline: 0 !important;
    box-shadow: none !important;
}

.hydrated {
    z-index: 100000;
}

.user-select-none {
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
}

.recharts-surface {
    overflow: visible;
}
